import PropTypes from 'prop-types';
import React, {useContext} from 'react';
import {
  Button,
  Flex,
  Grid,
  Input,
  Stack,
  Text,
  useBreakpointValue,
  useToast
} from '@chakra-ui/react';
import {USER_FRAGMENT} from '../utils/queries';
import {UserContext} from '../utils';
import {gql, useMutation} from '@apollo/client';

const UPDATE_USER = gql`
  mutation UpdateUser($name: String!) {
    updateUser(name: $name) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

const UPDATE_PASSWORD = gql`
  mutation UpdatePassword($password: String!, $confirmPassword: String!) {
    updatePassword(password: $password, confirmPassword: $confirmPassword) {
      id
      updatedAt
    }
  }
`;

function AccountForm({title, buttonText, children, mutation}) {
  const toast = useToast();
  const [mutate, {loading, error}] = useMutation(mutation, {
    onCompleted() {
      toast({
        title: 'Success',
        description: 'Your account has been updated',
        status: 'success'
      });
    }
  });

  function handleSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    mutate({variables: Object.fromEntries(formData)});
  }

  return (
    <Grid
      templateColumns={{
        md: '150px 1fr',
        lg: '200px 1fr'
      }}
      gap="2"
    >
      <Text fontSize={{md: 'lg'}}>{title}</Text>
      <Flex as="form" onSubmit={handleSubmit} direction="column" grow="1">
        <Stack mb={{base: 4, md: 6}} spacing={{base: 3, md: 4}}>
          {error && <Text color="red.500">{error.message}</Text>}
          {children}
        </Stack>
        <Button type="submit" isLoading={loading} ml="auto">
          {buttonText}
        </Button>
      </Flex>
    </Grid>
  );
}

AccountForm.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  mutation: PropTypes.object.isRequired
};

export default function AccountForms() {
  const inputSize = useBreakpointValue({md: 'lg'});
  const {user} = useContext(UserContext);
  return (
    <Stack key={user.updatedAt} mb="8" spacing="8">
      <AccountForm
        mutation={UPDATE_USER}
        title="User information"
        buttonText="Save changes"
      >
        <Input
          isRequired
          size={inputSize}
          placeholder="Name"
          name="name"
          defaultValue={user.name}
        />
        <Input
          isReadOnly
          isDisabled
          size={inputSize}
          placeholder="Email"
          type="email"
          name="email"
          defaultValue={user.email}
        />
      </AccountForm>
      <AccountForm
        mutation={UPDATE_PASSWORD}
        title="Change password"
        buttonText="Reset password"
      >
        <Input
          isRequired
          size={inputSize}
          name="password"
          placeholder="New password"
          type="password"
        />
        <Input
          isRequired
          size={inputSize}
          name="confirmPassword"
          placeholder="Confirm new password"
          type="password"
        />
      </AccountForm>
    </Stack>
  );
}
