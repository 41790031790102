import CreditCardForm from './CreditCardForm';
import PaymentMethod from './PaymentMethod';
import React, {useContext, useState} from 'react';
import {Box, Button, Flex, chakra} from '@chakra-ui/react';
import {UserContext} from '../utils';

export default function BillingForm() {
  const {user} = useContext(UserContext);
  const [formShown, setFormShown] = useState(!user.paymentMethod);

  if (formShown) {
    return (
      <CreditCardForm
        onCancel={user.paymentMethod ? () => setFormShown(false) : null}
      />
    );
  }

  return (
    <Flex align="center">
      <PaymentMethod lineHeight="normal" card={user.paymentMethod}>
        <Box color="gray.500" fontSize="sm">
          exp. {user.paymentMethod.expMonth}/
          {user.paymentMethod.expYear.toString().slice(-2)}
        </Box>
      </PaymentMethod>
      <Button
        rounded="full"
        colorScheme="indigo"
        ml="auto"
        onClick={() => setFormShown(true)}
      >
        <span>
          Change
          <chakra.span display={{base: 'none', md: 'inline'}}>
            {' '}
            payment method
          </chakra.span>
        </span>
      </Button>
    </Flex>
  );
}
