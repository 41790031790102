import PropTypes from 'prop-types';
import React from 'react';
import {Box, Button, Text, chakra, useBreakpointValue} from '@chakra-ui/react';
import {
  CARD_ELEMENT_WRAPPER_PROPS,
  useCardElementOptions,
  useCreatePaymentMethod
} from '../utils';
import {CardElement} from '@stripe/react-stripe-js';

export default function CreditCardForm({onCancel}) {
  const cardElementOverrides = useBreakpointValue({
    md: {
      lineHeight: '46px',
      fontSize: '18px'
    }
  });
  const cardElementOptions = useCardElementOptions(cardElementOverrides);

  const [createPaymentMethod, {loading, error, ready}] = useCreatePaymentMethod(
    onCancel
  );

  return (
    <div>
      {error && (
        <Text mb="4" color="red.500">
          {error.message}
        </Text>
      )}
      <Box {...CARD_ELEMENT_WRAPPER_PROPS}>
        <CardElement
          onReady={element => element.focus()}
          options={cardElementOptions}
        />
      </Box>
      <Box mt="4" textAlign="right">
        {onCancel && (
          <Button mr="3" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          isDisabled={!ready}
          isLoading={loading}
          colorScheme="pink"
          onClick={createPaymentMethod}
        >
          <span>
            Save
            <chakra.span display={{base: 'none', md: 'inline'}}>
              {' '}
              payment method
            </chakra.span>
          </span>
        </Button>
      </Box>
    </div>
  );
}

CreditCardForm.propTypes = {
  onCancel: PropTypes.func
};
