import AccountForms from '../components/AccountForms';
import BillingForm from '../components/BillingForm';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Layout from '../components/Layout';
import NoSsr from '@mpth/react-no-ssr';
import React from 'react';
import RequireAuth from '../components/RequireAuth';
import {
  BILLING_WRAPPER_PROPS,
  CONTAINER_GAP,
  CONTAINER_PADDING_X
} from '../utils';
import {Box, Heading, Text} from '@chakra-ui/react';
import {Helmet} from 'react-helmet';

export default function Account() {
  return (
    <Layout>
      <NoSsr>
        <RequireAuth>
          <Helmet title="Account settings" />
          <Box flexGrow="1">
            <Header />
            <Box
              maxW="container.md"
              w="full"
              mx="auto"
              px={CONTAINER_PADDING_X}
              py={CONTAINER_GAP}
            >
              <Heading size="xl" mb={{base: 4, md: 6}}>
                My account
              </Heading>
              <AccountForms />
              <Box p={[4, 5, 6]} {...BILLING_WRAPPER_PROPS}>
                <Heading as="h3" id="billing" mb="2" size="lg">
                  Billing settings
                </Heading>
                <Text mb="4" maxW="md">
                  The payment method configured here will be used for all
                  project slot purchases and upgrades.
                </Text>
                <BillingForm />
              </Box>
            </Box>
            <Footer />
          </Box>
        </RequireAuth>
      </NoSsr>
    </Layout>
  );
}
